import React from 'react';
import { navigate } from 'gatsby';

import { CaseNew } from '../../components/Case/CaseNew';
import Layout from '../../components/MenuLayout';

export default () => {
  const onCompleted = (uuid) => {
    navigate(`/case/${uuid}`);
  };

  return (
    <Layout>
      <CaseNew handleCompleted={onCompleted} />
    </Layout>
  );
};
