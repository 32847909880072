/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useQuery, useMutation } from '@apollo/react-hooks';
import { Box, Grid, Paper } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import Loading from '../Common/Loading';
import { medicalSpecialtyList } from '../../utils/medicalSpecialtyList';
import { GET_PATIENTS_ON_NEW_CASE, GET_RECENT_MEDICAL_CASES } from '../../graphql/queries';
import { CREATE_MEDICAL_CASE } from '../../graphql/mutations';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { SectionBar } from '../../componentsUI/SectionBar';
import { ScrollableContainer } from '../Common/ScrollableContainer';
import { CaseForm } from './CaseForm';
import useStyles from '../../components/Case/styled/CaseFormMakeStyles';
import { CaseSelectPatient } from './CaseSelectPatient';
import { NewPatientDialog } from '../Patient/modal/PatientNewDialog';
import { AlertUI } from '../../componentsUI/Alert';
import { networkErrorParse } from '../../utils/ErrorGraphQLUtils';

export const CaseNew = ({ handleCompleted, location }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const hospitalUuid = useSelector((state) => state.hospital.uuid);
  const [serverError, setServerError] = useState();
  const [step, setStep] = useState(0);
  const [search, setSearch] = useState('');
  const [medicalCasePatient, setMedicalCasePatient] = useState(location && location.state ? location.state.patient : null);
  const [newPatientModal, setNewPatientModal] = useState(false);
  const [creatingPatient, setCreatingPatient] = useState(false);

  const [createCase, { loading: updating }] = useMutation(
    CREATE_MEDICAL_CASE,
    {
      onCompleted: ({ createMedicalCase: medicalCase }) => {
        handleCompleted(medicalCase.uuid);
      },
      onError: (error) => {
        if (error && error.graphQLErrors && error.graphQLErrors.length) {
          setServerError(error.graphQLErrors[0].message);
        }
      },
      refetchQueries: [{
        query: GET_RECENT_MEDICAL_CASES,
        variables: {
          hospitalUuid,
          first: 20,
          orderBy: {
            field: 'CREATED_AT',
            direction: 'DESC',
          },
        },
      }],
      awaitRefetchQueries: true,
    },
  );

  // Get simple patients data belonging to hospital
  const { data, error, loading } = useQuery(
    GET_PATIENTS_ON_NEW_CASE,
    {
      variables: {
        hospitalUuid,
        orderBy: { field: 'SURNAME', direction: 'ASC' },
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const patients = data && data.listPatients && data.listPatients.edges;

  const goBack = () => window.history.back();

  const backButton = { label: t('return'), icon: Close, onClick: goBack };

  const submitAction = (values) => {
    createCase({
      variables: {
        medicalCase: {
          patientUuid: medicalCasePatient.uuid,
          title: values.title,
          specialty: values.specialty,
          subspecialty: values.subspecialty,
          active: values.active === 'true',
          description: values.description,
        },
      },
    }).then();
  };

  const showServerError = () => {
    const message = serverError.includes('You are not allowed to perform this action')
      ? t('not.allowed.to.perform.action')
      : t('server.error');
    return <AlertUI severity="error" title="Error">{message}</AlertUI>;
  };

  const patientInfo = medicalCasePatient && `${medicalCasePatient.name} ${medicalCasePatient.surname}`;
  const messageError = networkErrorParse(error);

  const initialValues = {
    patient: patientInfo,
    title: '',
    specialty: medicalSpecialtyList[0].value,
    subspecialty: '',
    active: 'true',
    description: '...',
    // share: '',
    // sharePermission: 'view',
  };

  if (loading && !data && !patients && !medicalCasePatient) return <Loading />;

  const handleCloseNew = () => { setNewPatientModal(false); };

  const handlePatientCreated = (patient) => {
    setCreatingPatient(true);
    setStep(1);
    setMedicalCasePatient(patient);
    setTimeout(() => {
      setCreatingPatient(false);
      handleCloseNew();
    }, 1000);
  };

  const formId = 'add-case-form';

  const selectPatientButtons = [
    { classes: classes.button, color: 'primary', variant: 'outlined', onClick: goBack, label: t('cancel') },
    { classes: classes.button, color: 'primary', variant: 'contained', onClick: () => setNewPatientModal(true), label: t('create.a.new.patient') },
  ];
  const createCaseButtons = [
    { classes: classes.button, color: 'primary', variant: 'outlined', onClick: () => setStep(0), label: t('back') },
    { classes: classes.button, color: 'primary', variant: 'contained', type: 'submit', form: formId, label: t('create') },
  ];

  if (messageError) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className={classes.error}>
            <AlertUI severity="error" title="Error">
              {t(messageError)}
            </AlertUI>
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Navbar className={classes.subheader}>
        <SectionBar title={`${t('new.case')} - ${t('part')} ${step + 1} ${t('of')} 2`} back={backButton} />
      </Navbar>
      <NewPatientDialog
        open={newPatientModal}
        onClose={handleCloseNew}
        onCreated={handlePatientCreated}
        inProgress={creatingPatient}
      />
      <Box className={classes.container}>
        <ScrollableContainer>
          {!messageError && (
            <Paper elevation={2} className={classes.wrapper}>
              {step === 0 && (
                <CaseSelectPatient
                  search={search}
                  setSearch={setSearch}
                  buttons={selectPatientButtons}
                  patients={patients}
                  loading={loading}
                  setMedicalCasePatient={setMedicalCasePatient}
                  setStep={setStep}
                  setNewPatientModal={setNewPatientModal}
                  goBack={goBack}
                />
              )}
              {step === 1 && (
                <CaseForm
                  formId={formId}
                  initialValues={initialValues}
                  buttons={createCaseButtons}
                  submitAction={submitAction}
                  serverError={serverError}
                  setServerError={setServerError}
                  submitting={updating}
                  onClose={goBack}
                  showServerError={showServerError}
                  setStep={setStep}
                />
              )}
            </Paper>
          )}
        </ScrollableContainer>
      </Box>
    </>
  );
};
