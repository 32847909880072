import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, DialogActions, Grid, Typography } from '@material-ui/core';

import SearchInput from '../Search/SearchInput';
import { isEmpty } from '../../utils/ObjectUtils';
import useStyles from './styled/CaseFormMakeStyles';
import { CardWrapperScrollUI } from '../Common/styled/CardWrapperUI';
import PatientSelectCard from '../Common/PatientCard/PatientSelectCard';
import Loading from '../Common/Loading';
import { AlertUI } from '../../componentsUI/Alert';

const searchStyles = makeStyles((theme) => ({
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: theme.spacing(1.5),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '20ch',
      '&:focus': {
        width: '45ch',
        maxWidth: '100%',
      },
    },
  },
}));

export const CaseSelectPatient = ({
  search,
  setSearch,
  buttons,
  patients,
  loading,
  setMedicalCasePatient,
  setStep,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const styles = searchStyles();

  const actionOnSelect = (patient) => {
    setMedicalCasePatient(patient);
    setStep(1);
  };

  const filteredPatients = isEmpty(patients) ? [] : patients.filter(
    (edge) => edge.node.name.toLowerCase().includes(search.trim().toLowerCase()) || edge.node.surname.toLowerCase().includes(search.trim().toLowerCase()),
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography className={classes.title}>
          {!filteredPatients.length
            ? t('first.step.create.a.patient')
            : t('first.step.select.or.create.a.patient')}
        </Typography>
      </Grid>

      {!!filteredPatients.length && (
        <Grid item xs={12}>
          <SearchInput
            name="patient-search"
            styles={styles}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={t('search.patient')}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        {loading && !filteredPatients.length && <Box><Loading /></Box>}
        {!filteredPatients.length ? (
          <AlertUI severity="info" title={t('info')}>
            {t('no.patients.available')}
          </AlertUI>
        ) : (
          <CardWrapperScrollUI className="mb-5">
            {filteredPatients.map((edge, index) => (
              <PatientSelectCard
                key={index.toString()}
                patient={edge.node}
                onClick={() => actionOnSelect(edge.node)}
              />
            ))}
          </CardWrapperScrollUI>
        )}
      </Grid>

      <Grid item xs={12}>
        <DialogActions>
          {!!buttons && buttons.map((item, index) => (
            <Button
              variant={item.variant}
              className={item.classes}
              key={index.toString()}
              color={item.color}
              onClick={item.onClick}
              type={item.type && item.type}
              form={item.form && item.form}
            >
              {item.label}
            </Button>
          ))}
        </DialogActions>
      </Grid>
    </Grid>
  );
};
