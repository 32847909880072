import React, { forwardRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@apollo/react-hooks';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import { ADD_PATIENT } from '../../../graphql/mutations';
import { PatientForm } from '../PatientForm';
import useStyles from '../styled/PatientMakeStyles';
import { GET_PATIENTS_ON_NEW_CASE } from '../../../graphql/queries';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export const NewPatientDialog = ({ open, refetch, onClose, onCreated, inProgress }) => {
  const { t } = useTranslation();
  const [serverError, setServerError] = useState(false);
  const hospitalUuid = useSelector((state) => state.hospital.uuid);
  const classes = useStyles();

  const [addPatient, { loading: submitting }] = useMutation(ADD_PATIENT, {
    onCompleted({ createPatient: patient }) {
      if (onCreated) {
        onCreated(patient);
        return;
      }
      refetch();
    },
    onError: (error) => {
      if (error && error.graphQLErrors && error.graphQLErrors.length) {
        setServerError(error.graphQLErrors[0].message);
      }
    },
    refetchQueries: [{
      query: GET_PATIENTS_ON_NEW_CASE,
      variables: {
        hospitalUuid,
        orderBy: { field: 'SURNAME', direction: 'ASC' },
      },
    }],
    awaitRefetchQueries: true,
  });

  const submitAction = async (values, callback) => {
    const patient = { ...values, hospitalUuid };
    addPatient({ variables: { patient } })
      .then(setTimeout(callback, 3000));
  };

  const initialPatient = {
    hospitalUuid,
    customId: '',
    name: '',
    surname: '',
    email: '',
    birthDate: '',
    identityNumber: '',
    nationality: '',
    insurance: '',
    insuranceNumber: '',
    phone: '',
    address: '',
    gender: '',
  };

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>{t('new.patient')}</DialogTitle>
      <PatientForm
        initialValues={initialPatient}
        submitLabel={t('create')}
        submitAction={submitAction}
        serverError={serverError}
        setServerError={setServerError}
        submitting={submitting || inProgress}
        onClose={onClose}
        formName="settings-patient-new-form"
      />
    </Dialog>
  );
};
