import { makeStyles } from '@material-ui/core/styles';

const patientSelectCardStyles = makeStyles((theme) => ({
  root: {
    width: 'calc (100% - 36px)',
    margin: 6,
    padding: '8px 16px',
    cursor: 'pointer',
    // backgroundColor: theme.palette.primary.light,
  },
  onClick: {
    width: '100%',
    padding: 0,
  },
  right: {
    fontSize: '1.125rem',
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
    '& .MuiBox-root': {
      color: '#A3A3A3',
    },
    '& span': {
      color: theme.palette.primary.light,
    },
  },
  name: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  id: {
    fontSize: '1.125rem',
  },
  age: {
    fontWeight: 600,
    fontSize: '.875em',
  },
}));

export default patientSelectCardStyles;
