import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Paper, Typography } from '@material-ui/core';

import patientSelectCardStyles from './styled/PatientSelectCardStyles';
import { getDateYears } from '../../../utils/dateTimeUtils';
import { FromNow } from '../FormattedDate';

export default ({ patient, onClick }) => {
  const styles = patientSelectCardStyles();
  const { t } = useTranslation();

  return (
    <Paper onClick={onClick} elevation={2} className={styles.root}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Typography className={styles.name}>
            {`${patient.name} ${patient.surname}`}
          </Typography>
          <Box className={styles.age}>
            {`${getDateYears(patient.birthDate)} ${t('years')}`}
          </Box>
          <Box className={styles.id}>
            {patient.customId}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} className={styles.right}>
          <Box>
            {t('last.modified')}
          </Box>
          <FromNow date={patient.createdAt} />
        </Grid>
      </Grid>
    </Paper>
  );
};
